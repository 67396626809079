export enum EndPoint {
    userInfo = 'Auth/GetUserInfo',
    billing = 'Billing/OrganizationBillingStatus',
    subscription = 'Subscriptions',
    dashboard = 'Sites/Dashboard',
    FeatureFlags = 'FeatureFlags/openbots.cloudserver',
}

export enum SitesEndPoint {
    sites = 'Sites',
}
