export const environment = {
    production: false,
    AUTH_ISSUER: 'https://cloud.openbots-dev.io',
    // AUTH_REDIRECT_URI: 'http://localhost:4200',
    // AUTH_POST_LOGOUT_URI: 'http://localhost:4200',
    AUTH_REDIRECT_URI: 'https://businessapps.openbots-dev.io',
    AUTH_POST_LOGOUT_URI: 'https://businessapps.openbots-dev.io',
    API_URL: 'https://cloudserver-api.openbots-dev.io',
    VERSION_URL: 'api/v4/',
    hub: 'notification',
    App_Insight_Key: '7a089d83-dc8c-4683-8e66-f7293173c246',
    isDebug: true,
    hubUrl: '/notification',
    healthUrl: '/healthcheck-ui',
    Feature_Workflow: true,
    Feature_BusinessRules: true,
    Feature_PDF_FORMS: true,
    Feature_Site: true,
    appConfig: 'appConfig.json',
    buyCredits: `https://dev.account.openbots.io/MySubscription?Product=OPENBOTS.CLOUDSERVER`,
    SubscriptionLink: `https://account.openbots.io/Buy/OpenBots.CloudServer`,
    OPENBOTS_SITES_URL: `openbots-dev.site`,
    Feature_Schema: true,
    previewShow: true,
    isDev: true,
    Feature_Form_Copilot: false,
    FORMS_COPILOT_API_URL:
        'https://graspnlp.azurewebsites.net/api/FormCoPilot_CreateForm?code=IownKw6bbSGMjCyxn1YDc_S1CFxMxeWmRiZBJH1bNtOeAzFurffSIQ==',
    Azure_AD_B2C: {
        ClientId: 'ddf5e665-9eda-4ea4-a045-22001577411d',
        Instance: "https://authenticate.openbots-dev.io/",
        GalaxyLoginUrl: "https://cloud.openbots-dev.io/Login",
        Domain: "linkgalaxydevtoazure.onmicrosoft.com",
        TenantId: "bf6dbb13-06da-40c1-b388-faf1922152d7",
        SignUpSignInPolicyId: "B2C_1_SignUpSignInDev",
        EditProfilePolicyId: "B2C_1_EditProfileDev",
        ResetPasswordPolicyId: "B2C_1_PasswordResetDev",
        ROPCPolicyId: "B2C_1_ROPC_AuthDev",
        GalaxyClientId: "2d7f9beb-a870-4f6c-9fea-363dec17c417",
        ClientSecret: "K6g8Q~xjF.PJhpVFd75ks3Ii9m~YV7no-SQUcddi",
        CallbackPath: "/signin-oidc",
        SignedOutCallbackPath: "/signout-callback-oidc",
        Scope: ['openid', 'email', 'profile', 'https://linkgalaxydevtoazure.onmicrosoft.com/adb2c/task.write', 'offline_access']
    }
};
