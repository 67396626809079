import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// @Injectable({
//   providedIn: 'root'
// }) // its a   base calss not a service  , its used as base service
export class BaseService {
    http: HttpClient;
    constructor(inject: Injector) {
        this.http = inject.get(HttpClient);
    }
    get apiUrl(): string {
        return `${environment.API_URL}/${environment.VERSION_URL}`;
    }

    get authIssuerUrl(): string {
        return `${environment.AUTH_ISSUER}/api/v1/`;
    }

    get apiUrl_v1() {
        return `${environment.API_URL}/api/v1/`;
    }

    getSubscriptions(endpoint: string, options?: any): Observable<any> {
        return this.http.get(`${this.authIssuerUrl}${endpoint}`, options);
    }

    // Below  methods are Generic
    get(endpoint: string, options: any = {}): Observable<any> {
        Object.assign(options, { withCredentials: true });
        // const mergedoptions = this.mergeDefaultOptions(options);
        return this.http.get(`${this.apiUrl}${endpoint}`, options);
    }
    // Get Multiple  Under Coinstruction
    put(endpoint: string, data: any, options: any = {}): Observable<any> {
        Object.assign(options, { withCredentials: true });
        return this.http.put(`${this.apiUrl}${endpoint}`, data, options);
    }

    post(endpoint: string, data?: any, options: any = {}): Observable<any> {
        Object.assign(options, { withCredentials: true });
        return this.http.post(`${this.apiUrl}${endpoint}`, data, options);
    }
    patch(endpoint: string, data: any, options: any = {}): Observable<any> {
        Object.assign(options, { withCredentials: true });
        return this.http.patch(`${this.apiUrl}${endpoint}`, data, options);
    }
    delete(endpoint: string, options: any = {}): Observable<any> {
        Object.assign(options, { withCredentials: true });
        return this.http.delete(`${this.apiUrl}${endpoint}`, options);
    }
    logger(key: any, message: any) {
        if (environment.isDev) {
            console.log(key, message);
        }
    }
    // end
}
