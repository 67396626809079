import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
// import { FileSizePipe } from 'ngx-filesize';
// import { TimeDatePipe } from '../pipe';
// import { AllFileSizePipe } from '../pipe/all-file-size.pipe';
// import { TimeZonePipe } from '../pipe/time-zone.pipe';
// import { UTCtoLocalTimeZonePipe } from '../pipe/utcto-local-time-zone.pipe';
// import { NbToastrService } from '@nebular/theme';
import { Location } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { UTCtoLocalTimeZonePipe } from '../shared/pipes/utcto-local-time-zone.pipe';
import { ItemsPerPage } from '../interfaces/itemsPerPage';
// import { ItemsPerPage } from '../../interfaces/itemsPerPage';
// import { Rule, Usage } from '../../interfaces/ipFencing';

@Injectable({
    providedIn: 'root',
})
export class SitesHelperService {
    organizationChanged = new BehaviorSubject<any>(
        localStorage.getItem('organizationId')
    );
    AppDefinitionChanged = new BehaviorSubject<any>(
        localStorage.getItem('AppID')
    );
    featuresLoaded = false;
    itemPerPage: ItemsPerPage[];
    constructor(private location: Location) {}

    noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }

    getItemsPerPage(): ItemsPerPage[] {
        return (this.itemPerPage = [
            // { id: 5, name: '5 per page' },
            { id: 10, name: '10 per page' },
            { id: 20, name: '20 per page' },
            { id: 30, name: '30 per page' },
            { id: 50, name: '50 per page' },
            { id: 100, name: '100 per page' },
        ]);
    }

    // transformDate(value, format: string) {
    //   const pipe = new TimeDatePipe();
    //   return pipe.transform(value, format);
    // }

    changeBoolean(value: boolean | string): string {
        if (value) return 'Yes';
        else return 'No';
    }

    getETagHeaders(etag: string) {
        const headers = new HttpHeaders({ 'If-Match': etag });
        return headers;
    }

    getChangedOrgId(): Observable<any> {
        return this.organizationChanged.asObservable();
    }
    getChangedAppId(): Observable<any> {
        return this.AppDefinitionChanged.asObservable();
    }

    UTCtoLocalTimeZone(value: any, format = 'lll') {
        const convertedTime = new UTCtoLocalTimeZonePipe();
        return convertedTime.transform(value, format);
    }

    UTCtoLocalTimeZoneWorkTask(value: any, format = 'll') {
        const convertedTime = new UTCtoLocalTimeZonePipe();
        return convertedTime.transform(value, format);
    }

    localToUTCTime(param: string) {
        return new Date(param);
    }
    bytesIntoMB(bytes: number): number {
        return bytes / (1024 * 1024);
    }

    bytesIntoGB(bytes: number): number {
        return bytes / (1024 * 1024 * 1024);
    }

    megaBytesIntiBytes(bytes: number): number {
        return bytes * (1024 * 1024);
    }

    gegaBytesIntiBytes(bytes: number): number {
        return bytes * (1024 * 1024 * 1024);
    }

    // bytesIntoMBorGB(storageSize: number): string[] {
    //   const data = new AllFileSizePipe();
    //   return data.transform(storageSize).split(' ');
    // }

    // UTCtoLocalTimeZone(value, format = 'lll') {
    //   const convertedTime = new UTCtoLocalTimeZonePipe();
    //   return convertedTime.transform(value, format);
    // }

    // UTCtoLocalTimeZoneWorkTask(value, format = 'll') {
    //   const convertedTime = new UTCtoLocalTimeZonePipe();
    //   return convertedTime.transform(value, format);
    // }

    // changedData(dataArray) {
    //   return dataArray.map((select) => {
    //     return Object.assign({}, select, {
    //       isSelected: false,
    //     });
    //   });
    // }
    // selectAll(dataArray, change) {
    //   return dataArray.map((select) => {
    //     return Object.assign({}, select, {
    //       isSelected: change,
    //     });
    //   });
    // }

    backScreen(): void {
        this.location.back();
    }
}
