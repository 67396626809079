import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { AuthService } from './services/auth.service';
// import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './interceptor/appinterceptor';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoaderService } from './services/loader.service';
import { CommonModule } from '@angular/common';
import { MomentUtcDateAdapter } from 'src/app/services/moment-utc-date-adopter.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { DialogModule } from 'primeng/dialog';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MessageService } from 'primeng/api';
import { ToastrModule } from 'ngx-toastr';
import { FeatureFlagService } from './services/feature-flag.service';

import { MsalModule, MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalRedirectComponent, MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfig, protectedResources, loginRequest } from './auth-config';
import { LogoutComponent } from './logout/logout.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'll',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
  }
  
  /**
  * MSAL Angular will automatically retrieve tokens for resources
  * added to protectedResourceMap. For more info, visit:
  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
  */
  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();
  
    protectedResourceMap.set(protectedResources.apiTodoList.endpoint, [
        {
            httpMethod: 'GET',
            scopes: [...protectedResources.apiTodoList.scopes.read]
        },
        {
            httpMethod: 'POST',
            scopes: [...protectedResources.apiTodoList.scopes.write]
        },
        {
            httpMethod: 'PUT',
            scopes: [...protectedResources.apiTodoList.scopes.write]
        },
        {
            httpMethod: 'DELETE',
            scopes: [...protectedResources.apiTodoList.scopes.write]
        }
    ]);
  
    return {
        interactionType: InteractionType.Popup,
        protectedResourceMap,
    };
  }
  
  /**
  * Set your default interaction type for MSALGuard here. If you have any
  * additional scopes you want the user to consent upon login, add them here as well.
  */
  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
    };
  }
  
@NgModule({
    declarations: [AppComponent, LogoutComponent],
    imports: [
        CommonModule,
        ProgressSpinnerModule,
        AppRoutingModule,
        DialogModule,
        HttpClientModule,
        AppLayoutModule,
        MsalModule,
        // OAuthModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        ToastrModule.forRoot(),
    ],
    providers: [
        AuthService,
        MessageService,
        LoaderService,
        FeatureFlagService,
        { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        //{ provide: LocationStrategy, useClass: HashLocationStrategy },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
