import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
// import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Subject, forkJoin } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from 'src/environments/environment';
import { EndPoint } from '../EndPointUrls/apiEndPoints.enum';
import { AppEndPoint } from '../EndPointUrls/appEndPoints.enum';
import { AppDefinition, ClaimsOrganizations, IUser } from '../interfaces/IUser';
import { BaseService } from './base.service';
import { FeatureFlagService } from './feature-flag.service';

import { catchError, filter, takeUntil } from 'rxjs/operators';

import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { b2cPolicies } from 'src/app/auth-config';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends BaseService {
    subscribtions?: Subscription;
    loggedInUser = {} as IUser;
    myOrganizations: ClaimsOrganizations[] = [];
    activeOrganizationId: string = '';
    activeOrgAvailable = false;
    ActiveTenantKey: string = '';
    myAppDefinition: AppDefinition[] = [];
    public loadApplication: boolean = false;
    userAvailable = new Subject<boolean>();
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private injector: Injector,
        public router: Router,
        private featureFlagService: FeatureFlagService
    ) {
        super(injector);
        // this.oAuthService.configure(OIDC_Config);
    }

    // setup_AND_Configure_OIDC() {
    //     this.store_RequestedUrl();
    //     if (this.oAuthService.hasValidAccessToken()) {
    //         this.getUserInfo_and_navigate();
    //     } else {
    //         this.oAuthService
    //             .loadDiscoveryDocumentAndLogin()
    //             .then((response) => {
    //                 if (response && this.oAuthService.hasValidAccessToken())
    //                     this.getUserInfo_and_navigate();
    //             });
    //     }
    // }
    unsubscribe_OIDC__Events() {
        this.subscribtions?.unsubscribe();
    }
    // subscribe_OIDC_Events() {
    //     this.subscribtions = this.oAuthService.events.subscribe((event) => {
    //         if (
    //             event.type === 'token_error' ||
    //             event.type == 'token_validation_error'
    //         ) {
    //             localStorage.clear();
    //             this.oAuthService.logOut();
    //         }
    //         if (event.type === 'invalid_nonce_in_state') {
    //             this.oAuthService.initCodeFlow();
    //         }
    //         if (event.type === 'token_expires') {
    //             this.oAuthService
    //                 .refreshToken()
    //                 .then()
    //                 .catch((err) => {});
    //         }
    //     });
    // }
    private store_RequestedUrl() {
        if (!sessionStorage.getItem('requested_url')) {
            sessionStorage.setItem(
                'requested_url',
                window.location.href.toString()
            );
        }
    }
    private navigate_to_requested_url() {

        const claims_obj = JSON.parse(
            localStorage.getItem('id_token_claims_obj')
          );
          if (claims_obj.identity_user_consent == 'True') {
            const url = environment.AUTH_ISSUER + '/members/terms';
            window.location.href = url;
          }

          let redirectUrl = sessionStorage.getItem('requested_url');
          let url = redirectUrl.replace(environment.AUTH_REDIRECT_URI, '');
          sessionStorage.removeItem('requested_url');
          if (url == '/' || url == '') {
            // this.router.navigateByUrl(url);
            this.router.navigate([AppEndPoint.dashboard]);
          } else {
            this.router.navigateByUrl(url);
          }
    }
    public getUserInfo_and_navigate() {
        this.getUserInfo().subscribe((userInfoandFeatureFlags) => {
            //user info
            // const user: IUser = userInfoandFeatureFlags[0];
            //  feature flags
            // this.featureFlagService.config = userInfoandFeatureFlags[1];

            this.loggedInUser = userInfoandFeatureFlags;

            if (localStorage.getItem('saveOrgId') != null) {
                for (let saveData of userInfoandFeatureFlags.myOrganizations) {
                    if (saveData.id == localStorage.getItem('saveOrgId')) {
                        localStorage.setItem('organizationId', saveData.id);
                        localStorage.setItem('tenantKey', saveData.tenantKey);
                        localStorage.setItem('orgname', saveData.name);

                        this.loggedInUser.activeOrganizationId = saveData.id;

                        this.myAppDefinition = saveData.appDefinitions;
                    }
                }
            } else if (localStorage.getItem('saveOrgId') == null) {
                this.loggedInUser.activeOrganizationId =
                    this.loggedInUser.myOrganizations[0].id;

                this.myAppDefinition =
                    this.loggedInUser.myOrganizations[0].appDefinitions;

                localStorage.setItem(
                    'organizationId',
                    this.loggedInUser.activeOrganizationId
                );
                localStorage.setItem(
                    'tenantKey',
                    this.loggedInUser.myOrganizations[0].tenantKey
                );
            }
            this.loadApp();
            // this.navigate_to_requested_url();
            // this.subscribe_OIDC_Events();
        });
    }
    private getUserInfo() {
        let userInfoResponse = this.http.get<IUser>(
            `${this.apiUrl}${EndPoint.userInfo}`
        ).pipe(catchError(error => {
            // handle error
            if (error.status === 401) {
              setTimeout(() => {
                //   this.loader.show()
                  sessionStorage.clear();
                  this.login();
              }, 500);
           }
            throw error;
          }));
        // let featureFlagresponse = this.http.get<IUser>(
        //     `${this.apiUrl}${EndPoint.FeatureFlags}`
        // );

        return userInfoResponse;
        // return forkJoin([userInfoResponse, featureFlagresponse]);
    }

    loadApp(reload: boolean = false) {
        if (reload == true) {
            this.loadApplication = false;
            setTimeout(() => {
                this.loadApplication = this.loadApplication = true;
            }, 100);
        } else {
            this.loadApplication = true;
        }
    }

    login(userFlowRequest?: RedirectRequest | PopupRequest) {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.msalService.instance.setActiveAccount(response.account);
                    });
            } else {
                this.msalService.loginPopup(userFlowRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.msalService.instance.setActiveAccount(response.account);
                    });
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
            } else {
                this.msalService.loginRedirect(userFlowRequest);
            }
        }
    }

    getAuthToken() {
        return localStorage.getItem('access_token');
    }
    logout() {
        localStorage.clear();
        sessionStorage.clear();
        // this.oAuthService.logOut();
    }
}
