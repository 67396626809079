import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
// import { NotfoundComponent } from './demo/components/notfound/notfound.component';
import { AppLayoutComponent } from './layout/app.layout.component';
import { LogoutComponent } from './logout/logout.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { LoginComponent } from './login/login.component';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: '',
                    component: AppLayoutComponent,
                    canActivate: [
                        MsalGuard
                    ],
                    children: [
                        {
                            path: 'dashboard',
                            loadChildren: () =>
                                import('./dashboard/dashboard.module').then(
                                    (m) => m.DashboardModule
                                ),
                        },
                        {
                            path: 'site',
                            loadChildren: () =>
                                import('./apps/site/site.module').then(
                                    (m) => m.SiteModule
                                ),
                        },
                        {
                            path: 'form',
                            loadChildren: () =>
                                import('./apps/form/form.module').then(
                                    (m) => m.FormsModule
                                ),
                        },
                        {
                            path: 'forms-designer',
                            loadChildren: () =>
                                import(
                                    './apps/forms-designer/forms-designer.module'
                                ).then((m) => m.FormsDesignerModule),
                        },
                        {
                            path: 'forms-template',
                            loadChildren: () =>
                                import(
                                    './apps/forms-template/forms-template.module'
                                ).then((m) => m.FormsTemplateModule),
                        },
                        {
                            path: 'form-submission',
                            loadChildren: () =>
                                import(
                                    './apps/form-submission/form-submission.module'
                                ).then((mod) => mod.FormSubmissionModule),
                        },
                        {
                            path: 'global-list',
                            loadChildren: () =>
                                import(
                                    './lists/global-list/global-list.module'
                                ).then((mod) => mod.GlobalListModule),
                        },
                        {
                            path: 'my-lists',
                            loadChildren: () =>
                                import('./lists/my-list/my-list.module').then(
                                    (mod) => mod.MyListModule
                                ),
                        },
                        {
                            path: 'lists',
                            loadChildren: () =>
                                import('./lists/lists.module').then(
                                    (m) => m.ListsModule
                                ),
                        },
                        {
                            path: '',
                            redirectTo: 'dashboard',
                            pathMatch: 'full',
                        },
                    ],
                },
                // { path: 'notfound', component: NotfoundComponent },
                { path: 'logout', component: LogoutComponent },
                { path: 'login', component: LoginComponent },
                {
                    // Needed for handling redirect after login
                    path: 'signin-oidc',
                    component: MsalRedirectComponent
                },
            ],
            {
                // Don't perform initial navigation in iframes or popups
                initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
            }
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
