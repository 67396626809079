import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { FeatureFlagService } from '../services/feature-flag.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];
    constructor(private featureflag: FeatureFlagService) {}
    ngOnInit() {
      
        this.model = [
            {
                label: 'Home',
                items: [
                    {
                        label: 'Dashboard',
                        icon: 'pi pi-fw pi-home',
                        image: '../../assets/icons/menu/Dashboard.svg',
                        routerLink: ['/'],
                    },
                ],
            },
            {
                label: 'Apps',
                icon: 'pi pi-fw pi-briefcase',
                items: [
                    // {
                    //     label: 'Schema',
                    //     icon: 'pi pi-fw pi-user',
                    //     items: [
                    //         {
                    //             label: 'All Schema',
                    //             icon: 'pi pi-fw pi-sign-in',
                    //             routerLink: ['/auth/login'],
                    //         },
                    //         {
                    //             label: 'Create Schema',
                    //             icon: 'pi pi-fw pi-times-circle',
                    //             routerLink: ['/auth/error'],
                    //         },
                    //     ],
                    // },
                    {
                        label: 'Sites',
                        icon: 'pi pi-fw pi-user',
                        image: '../../assets/icons/menu/site-map.svg',
                        items: [
                            {
                                label: 'Sites',
                                icon: 'pi pi-fw pi-sign-in',
                                image: '../../assets/icons/menu/sites1.png',
                                routerLink: ['/site'],
                            },
                            // {
                            //     label: 'Create Site',
                            //     icon: 'pi pi-fw pi-times-circle',
                            //     routerLink: ['/site/detail'],
                            // },
                        ],
                    },
                    {
                        label: 'Forms',
                        icon: 'pi pi-fw pi-user',
                        image: '../../assets/icons/menu/site-map.svg',
                        items: [
                            {
                                label: 'Forms',
                                icon: 'pi pi-fw pi-sign-in',
                                image: '../../assets/icons/menu/forms.png',
                                routerLink: ['/form'],
                            },
                            // {
                            //     label: 'Create Form',
                            //     icon: 'pi pi-fw pi-times-circle',
                            //     routerLink: ['/forms-designer'],
                            // },
                            {
                                label: 'Templates',
                                icon: 'pi pi-fw pi-times-circle',
                                image: '../../assets/icons/menu/forms.png',
                                routerLink: ['/forms-template'],
                            },
                            {
                                label: 'Submissions',
                                icon: 'pi pi-fw pi-times-circle',
                                image: '../../assets/icons/menu/submission.png',
                                routerLink: ['/form-submission'],
                            },
                        ],
                    },
                ],
            },
            {
                label: 'Lists',
                icon: 'pi pi-fw pi-user',
                items: [
                    {
                        label: 'My Lists',
                        icon: 'pi pi-fw pi-sign-in',
                        image: '../../assets/icons/menu/WorkItems.svg',
                        routerLink: ['/my-lists'],
                    },
                    {
                        label: 'Global Lists',
                        icon: 'pi pi-fw pi-times-circle',
                        image: '../../assets/icons/menu/WorkItems.svg',
                        routerLink: ['/global-list'],
                    },
                ],
            },
        ];
    }
}
