import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
// import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {
    BehaviorSubject,
    catchError,
    forkJoin,
    Observable,
    retry,
    throwError,
    timeout,
} from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { Router } from '@angular/router';
import { EndPoint, SitesEndPoint } from '../EndPointUrls/apiEndPoints.enum';
import { SitesDashboardCount } from '../interfaces/dashboard';

@Injectable({
    providedIn: 'root',
})
export class BusinessAppsHttpService extends BaseService {
    countapi = 0;

    constructor(private injector: Injector, public router: Router) {
        super(injector);
    }

    getDashboardCounts(options: any = {}): Observable<any> {
        Object.assign(options, { withCredentials: true });

        return this.http.get<SitesDashboardCount>(
            `${this.apiUrl}${EndPoint.dashboard}`,
            options
        );
    }

    getProcesses(): Observable<any> {
        return this.http.get('https://discovery-api.openbots-dev.io/api/v1/' + 'BusinessProcesses', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("id_token") }) });
    }

    getSites(odataQuery: string, options: any = {}): Observable<any> {
        Object.assign(options, { withCredentials: true });
        // const mergedoptions = this.mergeDefaultOptions(options);
        return this.http.get<any>(
            `${this.apiUrl}${SitesEndPoint.sites}${odataQuery}`,
            options
        );
    }

    getOrganizationId(): string | null {
        return localStorage.getItem('organizationId');
    }

    postbyUrl(endpoint: string, body: any): Observable<any> {
        // 120000  2min
        // 180000 3min
        // 90000 1.5 seconds
        // 60000 1 seconds
        return this.http
            .post(`${endpoint}`, body, {
                // reportProgress: true,
                // observe: 'events',
            })
            .pipe(timeout(90000), retry(2), catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `,
                error.error
            );
        }
        // Return an observable with a user-facing error message.
        return throwError(
            () => new Error('Something bad happened; please try again later.')
        );
    }

     // get user info and cloud server features
  getUserInfoAndFeatures(userInfoEndpoint: string, featureFlagsEndpoint: string, options?:any): Observable<any> {
    let response1 = this.http.get(`${this.apiUrl}${userInfoEndpoint}`, options);
    let response2 = this.http.get(`${this.apiUrl}${featureFlagsEndpoint}`, options);

    return forkJoin([response1, response2]);

  }
}
