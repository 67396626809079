import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'uTCtoLocalTimeZone',
})
export class UTCtoLocalTimeZonePipe implements PipeTransform {
  transform(value: unknown, format = 'lll'): string {
    return value ? moment.utc(value).local().format(format) : null;
  }
}
