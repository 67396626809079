import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagService extends BaseService {
    config: any = null;
    configUrl = `FeatureFlags/`;
    getTenantkeyValue: any = null;
    constructor(private injector: Injector) {
        super(injector);
    }

    loadConfig(serviceTag: any) {
        this.get(`${this.configUrl}${serviceTag}`).subscribe((data: any) => {
            this.config = data;
        });
    }

    isFeatureEnabled(key: string): Boolean | void {
        if (this.config) {
            const feature = this.config.find((elem: any) => elem.name == key);
            return feature ? feature.flag : false;
        }
    }

    getTenantKey(key?: any) {
        this.getTenantkeyValue = key;
        return this.getTenantkeyValue;
    }
}
