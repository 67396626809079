import { Component, OnInit, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { b2cPolicies } from '../auth-config';
import { Router } from '@angular/router';

import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { Subject, filter, takeUntil } from 'rxjs';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
    acr?: string,
    tfp?: string,
};

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    loginDisplay = false;
    constructor(
        // private msalService: MsalService,
        private router: Router,
        // private msalBroadcastService: MsalBroadcastService,
        // @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
        ) { }

    public ngOnInit(): void {

        
        // console.log(activeAccount, 'activeAccount');
        
        // this.router.navigate(['dashboard']);

        
        // this.msalService.handleRedirectObservable().subscribe({
        //     next: (result: AuthenticationResult) => {
        //       // Perform actions related to user accounts here
        //       console.log('result handle redirection', result);
              
        //     },
        //     error: (error) => console.log(error)
        //   });

        // const queryReturnIsAuth = this.GetParam('isAuthenticated');
        // if (queryReturnIsAuth == 'true') {
        //     if (!localStorage.getItem('id_token')) {
        //         this.login();
        //   }
        // } else {
        // // if (!localStorage.getItem('id_token')) {
            
        // //   }
        // }
          
        // this.setLoginDisplay();

        // this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

        /**
         * You can subscribe to MSAL events as shown below. For more info,
         * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
         */
        // this.msalBroadcastService.msalSubject$
        //     .pipe(
        //         filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        //     )
        //     .subscribe((result: EventMessage) => {
        //        
        //         if (this.msalService.instance.getAllAccounts().length === 0) {
        //             window.location.pathname = "/";
        //         } else {
        //             this.setLoginDisplay();
        //         }
        //     });

        // this.msalBroadcastService.inProgress$
        //     .pipe(
        //         filter((status: InteractionStatus) => status === InteractionStatus.None),
        //         takeUntil(this._destroying$)
        //     )
        //     .subscribe(() => {
        //         this.setLoginDisplay();
        //         this.checkAndSetActiveAccount();
        //     })

        // this.msalBroadcastService.msalSubject$
        //     .pipe(
        //         filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        //             || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        //             || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        //         takeUntil(this._destroying$)
        //     )
        //     .subscribe((result: EventMessage) => {
                

        //         let payload = result.payload as AuthenticationResult;

        //         console.log('result payload', payload);

        //         // extension_Organizations
        //         localStorage.setItem('id_token', payload.idToken);

        //         // this.authService.myOrganizations


        //         let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        //         if (idtoken.acr === b2cPolicies.names.signUpSignIn || idtoken.tfp === b2cPolicies.names.signUpSignIn) {
        //             this.msalService.instance.setActiveAccount(payload.account);
        //         }

        //         /**
        //          * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
        //          * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
        //          * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
        //          */
        //         if (idtoken.acr === b2cPolicies.names.editProfile || idtoken.tfp === b2cPolicies.names.editProfile) {

        //             // retrieve the account from initial sing-in to the app
        //             const originalSignInAccount = this.msalService.instance.getAllAccounts()
        //                 .find((account: AccountInfo) =>
        //                     account.idTokenClaims?.oid === idtoken.oid
        //                     && account.idTokenClaims?.sub === idtoken.sub
        //                     && ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr === b2cPolicies.names.signUpSignIn
        //                         || (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp === b2cPolicies.names.signUpSignIn)
        //                 );

        //             let signUpSignInFlowRequest: SsoSilentRequest = {
        //                 authority: b2cPolicies.authorities.signUpSignIn.authority,
        //                 account: originalSignInAccount
        //             };

        //             // silently login again with the signUpSignIn policy
        //             this.msalService.ssoSilent(signUpSignInFlowRequest);
        //         }

        //         /**
        //          * Below we are checking if the user is returning from the reset password flow.
        //          * If so, we will ask the user to reauthenticate with their new password.
        //          * If you do not want this behavior and prefer your users to stay signed in instead,
        //          * you can replace the code below with the same pattern used for handling the return from
        //          * profile edit flow (see above ln. 74-92).
        //          */
        //         if (idtoken.acr === b2cPolicies.names.resetPassword || idtoken.tfp === b2cPolicies.names.resetPassword) {
        //             let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
        //                 authority: b2cPolicies.authorities.signUpSignIn.authority,
        //                 prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
        //                 scopes: []
        //             };

        //             this.login(signUpSignInFlowRequest);
        //         }

        //         return result;
        //     });

        // this.msalBroadcastService.msalSubject$
        //     .pipe(
        //         filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        //         takeUntil(this._destroying$)
        //     )
        //     .subscribe((result: EventMessage) => {
        //         // Checking for the forgot password error. Learn more about B2C error codes at
        //         // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
        //         if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
        //             let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
        //                 authority: b2cPolicies.authorities.resetPassword.authority,
        //                 scopes: [],
        //             };

        //             this.login(resetPasswordFlowRequest);
        //         };
        //     });


    }

    ngAfterViewInit(): void {

        // const activeAccount =
        // this.msalService.instance.getActiveAccount() ||
        // this.msalService.instance.getAllAccounts()[0];

        // // sessionStorage.clear();
        
        // console.log('activeAccount', activeAccount);
        // const queryReturnIsAuth = this.GetParam('isAuthenticated');
        // const queryReturnReturn = this.GetParam('returnurl');

        // setTimeout(() => {
        //     if (!activeAccount && queryReturnIsAuth == 'true') {
        //         // window.location.href = `https://milkyway.openbots-dev.io/Login?returnurl=${window.location.href}`;
        //         this.login();
        //     }
        // }, 5000);
    }

    setLoginDisplay() {
        // this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
    }

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        // let activeAccount = this.msalService.instance.getActiveAccount();

        // if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
        //     let accounts = this.msalService.instance.getAllAccounts();
        //     // add your code for handling multiple accounts here
        //     this.msalService.instance.setActiveAccount(accounts[0]);
        // }
    }


    GetParam(name:any){
        const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if(!results){
          return '';
        }
        return results[1] || '';
      }

    
    //   login(userFlowRequest?: RedirectRequest | PopupRequest) {
    //     if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
    //         if (this.msalGuardConfig.authRequest) {
    //             this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
    //                 .subscribe((response: AuthenticationResult) => {
    //                     this.msalService.instance.setActiveAccount(response.account);
    //                 });
    //         } else {
    //             this.msalService.loginPopup(userFlowRequest)
    //                 .subscribe((response: AuthenticationResult) => {
    //                     this.msalService.instance.setActiveAccount(response.account);
    //                 });
    //         }
    //     } else {
    //         sessionStorage.clear();
    //         if (this.msalGuardConfig.authRequest) {
    //             try {
    //             this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    //             } catch (error) {
    //                 console.log(error, 'error login redirect');
                                        
    //             }
    //         } else {
    //             try {
    //                 this.msalService.loginRedirect(userFlowRequest);   
    //             } catch (error) {
    //                 console.log(error, 'error login redirect');
    //             }
    //         }
    //     }
    // }

    // unsubscribe to events when component is destroyed
    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
