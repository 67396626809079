<ul class="layout-menu">
    <ng-container *ngFor="let item of model; let i = index">
        <li
            app-menuitem
            *ngIf="!item.separator"
            [item]="item"
            [index]="i"
            [root]="true"
        ></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
    </ng-container>
</ul>
