import { Component, OnInit, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

@Component({
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(private authService: MsalService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) { }

    public ngOnInit(): void {
    }

    public ngAfterViewInit(): void {
        const activeAccount =
            this.authService.instance.getActiveAccount() ||
            this.authService.instance.getAllAccounts()[0];

        // Extract login hint to use as logout hint
        const logoutHint = localStorage.getItem('id_token');

        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            this.authService.logoutPopup({
                account: activeAccount,
            });
            
        } else {
            this.authService.instance.initialize().then(()=> {
                this.authService.logoutRedirect({
                    account: activeAccount,
                    // postLogoutRedirectUri: logoutUrl,
                    // logoutHint: activeAccount.idToken,
                    idTokenHint: logoutHint
                });
            })
        }
        localStorage.clear();
        sessionStorage.clear();
    }

    ngOnDestroy() { }
}
