import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
show_spinner=new BehaviorSubject<boolean>(true);

constructor() { }
show(){this.show_spinner.next(true);}
hide(){this.show_spinner.next(false);}
}
